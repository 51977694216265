import request from '@/utils/request'

/**
 * 页面模版相关接口
 * */

// 获取页面模版列表
export function getPage(params) {
  return request({
    url: '/cs/admin/page/get',
    method: 'get',
    params,
  })
}

// 新增页面模版列表
export function addPage(data) {
  return request({
    url: '/cs/admin/page/add',
    method: 'post',
    data,
  })
}

// 编辑页面模版列表
export function editPage(data) {
  return request({
    url: '/cs/admin/page/update',
    method: 'put',
    data,
  })
}

// 修改页面模版状态

export function pageStatus(id, status) {
  return request({
    url: `/cs/admin/page/update/${status}/${id}`,
    method: 'put',
  })
}

// 通过id 获取详情接口

export function getPageDetailData(id) {
  return request({
    url: `/cs/admin/page/get/${id}`,
    method: 'get',
  })
}

// 删除页面模版
export function deletePage(id) {
  return request({
    url: `/cs/admin/page/del/${id}`,
    method: 'delete',
  })
}

// 事件列表
export function getEventPage(data) {
  return request({
    url: '/user/admin/',
    method: 'post',
    data,
  })
}

// 新增事件
export function saveEventPage(data) {
  return request({
    url: '/save',
    method: 'post',
    data,
  })
}

// 编辑事件
export function editEventPage(data) {
  return request({
    url: '/user/admin/event/update',
    method: 'post',
    data,
  })
}

// 事件类型列表
export function getEventType(data) {
  return request({
    url: '/user/admin/event/type/page',
    method: 'post',
    data,
  })
}

//新增事件类型
export function saveEventType(data) {
  return request({
    url: '/user/admin/event/type/save',
    method: 'post',
    data,
  })
}

// 编辑事件类型
export function editEventType(data) {
  return request({
    url: '/user/admin/event/type/update',
    method: 'post',
    data,
  })
}

/**
 * @description: 设置展会内页为主页
 * @return {*}
 */
export function setExhHomePage(data) {
  return request({
    url: '/user/admin/hold/exhibition/setExPageHome',
    method: 'post',
    data,
  })
}
